// Topik
export { ReactComponent as EkonomiSvg } from 'assets/icons/Topics/ekonomi.svg';
export { ReactComponent as LingkunganSvg } from 'assets/icons/Topics/lingkungan.svg';
export { ReactComponent as Bundaya } from 'assets/icons/Topics/budaya.svg';
export { ReactComponent as PerlindungamSvg } from 'assets/icons/Topics/perlindungam.svg';
export { ReactComponent as PendidikanSvg } from 'assets/icons/Topics/pendidikan.svg';
export { ReactComponent as PemerintahanSvg } from 'assets/icons/Topics/pemerintahan.svg';
export { ReactComponent as PendukungSvg } from 'assets/icons/Topics/pendukung.svg';
export { ReactComponent as KetertibanSvg } from 'assets/icons/Topics/ketertiban.svg';
export { ReactComponent as PembangunanSvg } from 'assets/icons/Topics/pembangunan.svg';
export { ReactComponent as PertahananSvg } from 'assets/icons/Topics/pertahanan.svg';

export { ReactComponent as EkonomiSvg2 } from 'assets/icons/Topics/ekonomi2.svg';
export { ReactComponent as LingkunganSvg2 } from 'assets/icons/Topics/lingkungan2.svg';
export { ReactComponent as Bundaya2 } from 'assets/icons/Topics/budaya2.svg';
export { ReactComponent as PerlindungamSvg2 } from 'assets/icons/Topics/perlindungam2.svg';
export { ReactComponent as PendidikanSvg2 } from 'assets/icons/Topics/pendidikan2.svg';
export { ReactComponent as PemerintahanSvg2 } from 'assets/icons/Topics/pemerintahan2.svg';
export { ReactComponent as PendukungSvg2 } from 'assets/icons/Topics/pendukung2.svg';
export { ReactComponent as KetertibanSvg2 } from 'assets/icons/Topics/ketertiban2.svg';
export { ReactComponent as PembangunanSvg2 } from 'assets/icons/Topics/pembangunan2.svg';
export { ReactComponent as PertahananSvg2 } from 'assets/icons/Topics/pertahanan2.svg';

//CMS
export { ReactComponent as APIManagementIcon } from 'assets/icons/CMS/APIManagement.svg';
export { ReactComponent as ApplicationMonitoring } from 'assets/icons/CMS/ApplicationMonitoring.svg';
export { ReactComponent as ContentManagementIcon } from 'assets/icons/CMS/ContentManagement.svg';
export { ReactComponent as DashboardManagementIcon } from 'assets/icons/CMS/DashboardManagement.svg';
export { ReactComponent as DataManagementIcon } from 'assets/icons/CMS/DataManagement.svg';
export { ReactComponent as KonfigurasiIcon } from 'assets/icons/CMS/Konfigurasi.svg';
export { ReactComponent as LogActivityIcon } from 'assets/icons/CMS/LogActivity.svg';
export { ReactComponent as UserManagementIcon } from 'assets/icons/CMS/UserManagement.svg';
export { ReactComponent as BudgetIcon } from 'assets/icons/CMS/Budget.svg';
export { ReactComponent as ChangeAndReleaseIcon } from 'assets/icons/CMS/ChangeAndRelease.svg';
export { ReactComponent as KnowledgeIcon } from 'assets/icons/CMS/Knowledge.svg';
export { ReactComponent as FeedbackIcon } from 'assets/icons/CMS/Feedback.svg';

export { ReactComponent as SidebarApiIcon } from 'assets/icons/Sidebars/sidebar-api.svg';
export { ReactComponent as SidebarConfigIcon } from 'assets/icons/Sidebars/sidebar-config.svg';
export { ReactComponent as SidebarContentIcon } from 'assets/icons/Sidebars/sidebar-content-management.svg';
export { ReactComponent as SidebarDataIcon } from 'assets/icons/Sidebars/sidebar-data-management.svg';
export { ReactComponent as SidebarUserIcon } from 'assets/icons/Sidebars/sidebar-user-management.svg';
export { ReactComponent as SidebarFeedbackIcon } from 'assets/icons/Sidebars/sidebar-feedback.svg';
export { ReactComponent as SidebarBudgetIcon } from 'assets/icons/Sidebars/sidebar-budget.svg';

//Data Browser
export { ReactComponent as TanpaKemiskinan } from 'assets/icons/Databrowser/Kemiskinan.svg';
export { ReactComponent as MengakhiriKelaparan } from 'assets/icons/Databrowser/Kelaparan.svg';
export { ReactComponent as KesehatanYangBaik } from 'assets/icons/Databrowser/Kesehatan.svg';
export { ReactComponent as PendidikanBermutu } from 'assets/icons/Databrowser/Pendidikan.svg';
export { ReactComponent as KesetaraanGender } from 'assets/icons/Databrowser/Gender.svg';
export { ReactComponent as AksesAirBersih } from 'assets/icons/Databrowser/Air.svg';
export { ReactComponent as EnergiBersih } from 'assets/icons/Databrowser/EnergiBersih.svg';
export { ReactComponent as PekerjaanLayak } from 'assets/icons/Databrowser/PekerjaanLayak.svg';
export { ReactComponent as InfrastrukturIndustri } from 'assets/icons/Databrowser/InfrastrukturIndustri.svg';
export { ReactComponent as MengurangiKetimpangan } from 'assets/icons/Databrowser/MengurangiKetimpangan.svg';
export { ReactComponent as KotaDanKomunitas } from 'assets/icons/Databrowser/KotaKomunitas.svg';
export { ReactComponent as KonsumsiDanProduksi } from 'assets/icons/Databrowser/KonsumsiProduksi.svg';
export { ReactComponent as PenangananPerubahanIklim } from 'assets/icons/Databrowser/PerubahanIklim.svg';
export { ReactComponent as EkosistemLaut } from 'assets/icons/Databrowser/EkosistemLaut.svg';
export { ReactComponent as EkosistemDarat } from 'assets/icons/Databrowser/EkosistemDarat.svg';
export { ReactComponent as Perdamaian } from 'assets/icons/Databrowser/Perdamaian.svg';
export { ReactComponent as Kemitraan } from 'assets/icons/Databrowser/Kemitraan.svg';
