import Keycloak from 'keycloak-js';
import { sdiEnv } from 'utils/constants';

export const initOptions = {
  checkLoginIframe: false,
};

const devConfig = {
	url: 'http://172.17.62.129:9090/auth',
	// url: 'https://cas.satudata.go.id/auth',
	// url: 'http://localhost:8080/auth',
	//url: 'https://sso.deltadatamandiri.com/auth',
	realm: 'satu-data-portal-test',
	clientId: 'satu-data-portal-client',
	// clientSecret: 'a9b6a16e-f755-4938-bcb3-30b50ce1e83a', d51315f4-5e36-4dec-ae3e-2d2cb121dc25
	authServerUrl: 'http://172.17.62.129:9090/auth',
	// authServerUrl: 'https://cas.satudata.go.id/auth',
	// authServerUrl: 'http://localhost:8080/auth',
	resource: 'satu-data-portal-client',
	publicClient: true,
	sslRequired: 'external',
	principalAttribute: 'preferred_username',
};

const stageConfig = {
	url: 'https://cas.satudata.go.id/auth',
	realm: 'satu-data-portal-test',
	clientId: 'satu-data-portal-client',
	// clientSecret: 'a9b6a16e-f755-4938-bcb3-30b50ce1e83a', d51315f4-5e36-4dec-ae3e-2d2cb121dc25
	authServerUrl: 'https://cas.satudata.go.id/auth',
	resource: 'satu-data-portal-client',
	publicClient: true,
	sslRequired: 'external',
	principalAttribute: 'preferred_username',
};

const prodConfig = {
	url: 'https://cas.data.go.id/auth/',
	realm: 'ckan-sdi',
	clientId: 'portal-satu-data-client',
	authServerUrl: 'https://cas.data.go.id/auth/',
	resource: 'portal-satu-data-client',
	publicClient: true,
	sslRequired: 'external',
	principalAttribute: 'preferred_username',
};

const configs = {
  staging: stageConfig,
  production: prodConfig,
  dev: devConfig,
};

const keycloakConfig = configs[sdiEnv] || configs['staging'];

const keyclock = new Keycloak(keycloakConfig);

export default keyclock;
