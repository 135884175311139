/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './history';
import appReducer from 'containers/App/reducer';
import authReducer from 'containers/Login/reducer';
import berandaReducer from 'containers/Beranda/reducer';
import katalogDataReducer from 'containers/KatalogData/reducer';
import cmsLogActifitiasReducer from 'containers/LogAktifitas/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    global: appReducer,
    auth: authReducer,
    beranda: berandaReducer,
    katalog: katalogDataReducer,
    cmsLogActifitias: cmsLogActifitiasReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });
}
